import React, { useEffect, useState } from 'react';
import bcrypt from 'bcryptjs';
import adminPasswordHash from '../auth/adminPasswordHash';
import AddPhoto from './AddPhoto';
import AdminLogin from './AdminLogin';

const AdminApp = () => {
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [loginSuccessful, setLoginSuccessful] = useState(false);

  useEffect(() => {
    const myCat = localStorage.getItem('myCat');
    if (myCat) {
      setIsAdminUser(bcrypt.compareSync(myCat, adminPasswordHash.value));
      setLoginSuccessful(false);
    }
  }, [loginSuccessful]);

  const handleSuccessfulLogin = password => {
    localStorage.setItem('myCat', password);
    setLoginSuccessful(true);
  };

  return (
    <>{isAdminUser ? <AddPhoto /> : <AdminLogin onSuccessfulLogin={handleSuccessfulLogin} />}</>
  );
};

export default AdminApp;
