import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getMonthsForYearsForImages, getSortOrder } from 'image-gallery/reducer/selectors.tsx';
import { months } from 'utils/date-time';
import MonthGroup from './MonthGroup';

const YearGroup = ({ year, openLightbox }) => {
  const monthsForYearsForImages = useSelector(getMonthsForYearsForImages);
  const sortOrder = useSelector(getSortOrder);

  const orderedMonthsForYear = _.orderBy(
    monthsForYearsForImages[year],
    month => months.findIndex(monthInOrder => monthInOrder === month),
    sortOrder,
  );

  return (
    <div>
      <div className="year-header">
        <h1>{year}</h1>
      </div>
      {orderedMonthsForYear.map(month => (
        <MonthGroup key={`${month}${year}`} month={month} year={year} openLightbox={openLightbox} />
      ))}
    </div>
  );
};

export default YearGroup;
