import React from 'react';
import { Icon, Upload } from 'antd';
import 'antd/dist/antd.css';

const UploadButton = ({
  disabled,
  onUpload,
  uploadedFiles,
  loading,
  uploadRequestHandler,
  onPreviewClick,
}) => {
  return (
    <Upload
      multiple
      name="avatar"
      listType="picture-card"
      defaultFileList={uploadedFiles}
      className="upload-list-inline"
      onChange={onUpload}
      onPreview={onPreviewClick}
      disabled={disabled}
      customRequest={uploadRequestHandler}
      showUploadList={{ showDownloadIcon: false, showPreviewIcon: true, showRemoveIcon: false }}
    >
      <div>
        <Icon type={loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    </Upload>
  );
};

export default UploadButton;
