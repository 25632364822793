import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Link } from 'react-router-dom';

import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Radio } from 'antd';
import allyLogoImg from 'assets/ally-logo.png';
import { initGallery as initGalleryAction } from './reducer/thunks.tsx';
import '../App.css';
import 'antd/dist/antd.css';
import { byId, sortOrder as sortOrderReducer } from './reducer/index.tsx';
import { getAllImages, getSortOrder, getYearsForImages } from './reducer/selectors.tsx';
import YearGroup from './components/YearGroup';
import useAuth from '../common-hooks/useAuth';

const Header = () => {
  const BIRTH_DATE = '2018-12-01';
  const age = moment().diff(BIRTH_DATE, 'years');
  const isAdminUser = useAuth();
  return (
    <div
      style={{
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h1 className="main-title">Leeroy</h1>
        <h2 className="sub-title">{age === 1 ? '1 year ' : `${age} years `} old Siberian cat</h2>
        {!!isAdminUser && (
          <div style={{ marginTop: '20px' }}>
            <Button>
              <Link style={{ fontFamily: `'Open Sans', sans-serif` }} to="/admin">
                Add Photo
              </Link>
            </Button>
          </div>
        )}
      </div>
      <div style={{ width: '50%', display: 'flex' }}>
        <img src={allyLogoImg} alt="Leeeroooyyyyyy" className="ally-logo" />
      </div>
    </div>
  );
};

const Gallery = ({ initGallery, toggleSortOrder }) => {
  useEffect(() => {
    initGallery();
    // eslint-disable-next-line
  }, []);

  const yearsForImages = useSelector(getYearsForImages);
  const sortOrder = useSelector(getSortOrder);
  const orderedYearsForImages = _.orderBy(
    yearsForImages,
    yearString => Number(yearString),
    sortOrder,
  );
  const allImages = useSelector(getAllImages);
  const sortedImages = _.orderBy(
    allImages,
    ['year', 'month', 'day'],
    Array(3).fill(sortOrder),
  ).map((img, index) => ({ ...img, index }));

  const [currentImageId, setCurrentImageId] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo: { id } }) => {
    setCurrentImageId(id);
    setViewerIsOpen(true);
  }, []);
  const closeLightbox = () => {
    setViewerIsOpen(false);
  };

  return (
    <div>
      <div className="sort-order-header">
        <Header />
        <div
          style={{ marginLeft: '10px', display: 'flex', flexDirection: 'row', marginTop: 'auto' }}
        >
          <Radio.Group buttonStyle="solid" value={sortOrder} onChange={toggleSortOrder}>
            <Radio.Button value="asc">
              <p style={{ fontFamily: `'Open Sans', sans-serif` }}>Chronological</p>
            </Radio.Button>
            <Radio.Button value="desc">
              <p style={{ fontFamily: `'Open Sans', sans-serif` }}>Most Recent</p>
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      {orderedYearsForImages.map(year => (
        <YearGroup year={year} openLightbox={openLightbox} />
      ))}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={sortedImages.findIndex(img => img.id === currentImageId)}
              views={sortedImages.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

const dispatchToProps = {
  addImages: byId.actions.add,
  editImage: byId.actions.edit,
  toggleSortOrder: sortOrderReducer.actions.toggle,
  initGallery: initGalleryAction,
};

export default connect(null, dispatchToProps)(Gallery);
