import moment from 'moment';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthNumberToEnumValue = monthNumber => months[monthNumber];

export const getDayFromDateTime = dateTime => moment(dateTime).day();
export const getDayOfYearFromDateTime = dateTime => moment(dateTime).dayOfYear();
export const getMonthValueFromDateTime = dateTime => moment(dateTime).month();
export const getMonthFromDateTime = dateTime => monthNumberToEnumValue(moment(dateTime).month());
export const getYearFromDateTime = dateTime => moment(dateTime).year();
