import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Gallery from './image-gallery/Gallery';
import AdminApp from './admin/AdminApp';

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <Gallery />
      </Route>
      <Route path="/admin">
        <AdminApp />
      </Route>
    </Switch>
  </Router>
);

export default App;
