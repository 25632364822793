/* eslint-disable radix */
export default function(a, b, tolerance) {
  /* where a is the first number, b is the second number,  and tolerance is a percentage
    of allowable error expressed as a decimal. 753,4466,.08 = 1:6, 753,4466,.05 = 14:83, */

  const bg = a > b ? a : b;
  const sm = a < b ? a : b;

  // eslint-disable-next-line consistent-return
  for (let index = 1; index < 10000; index++) {
    const d = sm / index;
    const res = bg / d;
    const howClose = Math.abs(res - res.toFixed(0));
    if (howClose < tolerance) {
      if (a > b) {
        return { height: parseInt(res.toFixed(0)), width: parseInt(index.toFixed(0)) };
      }
      return { height: parseInt(index.toFixed(0)), width: parseInt(res.toFixed(0)) };
    }
  }
  throw Error();
}
