import { combineReducers } from 'redux';
import { createSlice } from '@reduxjs/toolkit';
import uuid from 'uuid/v4';
import _ from 'lodash';
import { ByIdState, ImageObject } from './types';
import {getMonthValueFromDateTime, getYearFromDateTime} from "../../utils/date-time";
import moment from "moment";

const isValidImage = (image: any) => _.has(image, ['id', 'height', 'width', 'capturedDate']);

const byIdReducers = {
  add: {
    reducer: (state: ByIdState, action: { payload: ImageObject[] }) => ({ ...state, ..._.keyBy(action.payload, 'id') }),
    prepare: (images: ImageObject[]) => ({
      payload: images ? images.map(img => ({
        ...img,
        year: getYearFromDateTime(img.capturedDate),
        month: getMonthValueFromDateTime(img.capturedDate),
        day: moment(img.capturedDate).date(),
        id: img.id || uuid()
      })
      ) : [],
    }),
  },
  edit: {
    reducer: (state: any, action: { payload: { id: string; }; }) => ({ ...state, [action.payload.id]: action.payload }),
    prepare: (image: any) => ({
      payload: isValidImage(image) ? image : undefined,
    }),
  },
};

export const byId = createSlice({
  reducers: byIdReducers,
  initialState: {},
  name: 'byId',
});

export const sortOrder = createSlice({
  name: 'sortOrder',
  initialState: 'desc',
  reducers: {
    change: {
      reducer: (state, action) => action.payload || state,
      // @ts-ignore
      prepare: (payload: string) => ({
        payload: payload.match(/asc|desc/) ? payload : undefined,
      }),
    },
    toggle: state => (state === 'asc' ? 'desc' : 'asc'),
  },
});

export default combineReducers({ byId: byId.reducer, sortOrder: sortOrder.reducer });
