import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import images from 'image-gallery/reducer/index.tsx';

const root = combineReducers({
  images,
});

const middleware = compose(
  applyMiddleware(thunk),
  window.devToolsExtension ? window.devToolsExtension() : f => f,
);

export default createStore(root, middleware);
