import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Icon, Input } from 'antd';
import { Link } from 'react-router-dom';
import bcrypt from 'bcryptjs';
import adminPasswordHash from '../auth/adminPasswordHash';

const AdminLogin = ({ onSuccessfulLogin }) => {
  const [passwordInput, setPasswordInput] = useState('');

  useEffect(() => {
    if (bcrypt.compareSync(passwordInput, adminPasswordHash.value)) {
      onSuccessfulLogin(passwordInput);
    }
  }, [passwordInput, onSuccessfulLogin]);

  const linkToGallery = (
    <Link to="/">
      <Icon type="back" />
      Back to Gallery
    </Link>
  );

  return (
    <div style={{ backgroundColor: '#FEFAF0', height: '100vh' }}>
      <Card
        title="Login"
        className="admin-form"
        style={{ margin: 'auto', marginTop: '20px' }}
        extra={linkToGallery}
      >
        <Form>
          <Form.Item>
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item>
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              onChange={({ target: { value } }) => setPasswordInput(value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AdminLogin;
