import _ from "lodash";
import { getYearFromDateTime, getMonthFromDateTime } from "../../utils/date-time";
import {ByIdState} from "./types";

// Unsorted
export const getYearsForImages = (state: { images: { byId: any; }; }) =>
    _(state.images.byId)
        .values()
        .map((img: { capturedDate: any; }) => getYearFromDateTime(img.capturedDate))
        .uniq()
        .value();

export const getMonthsForYearsForImages = (state: { images: { byId: any; }; }) =>
    _(state.images.byId)
        .values()
        .groupBy((image: { capturedDate: any; }) => getYearFromDateTime(image.capturedDate))
        .mapValues((imagesForYear: any) =>
            _(imagesForYear)
                .map((image: { capturedDate: any; }) => getMonthFromDateTime(image.capturedDate))
                .uniq()
                .value(),
        )
        .value();

const isImageFromMonthAndYear = (image: { capturedDate: string; }, month: string, year: number) =>
    getMonthFromDateTime(image.capturedDate) === month &&
    getYearFromDateTime(image.capturedDate) === year;

// Unsorted
export const getImagesForMonthAndYear = (month: string, year: number) => (state: { images: { byId: any; }; }) =>
    _(state.images.byId)
        .values()
        .filter((image: { capturedDate: string; }) => isImageFromMonthAndYear(image, month, year))
        .value();

export const getAllImages = (state: { images: { byId: ByIdState | ArrayLike<unknown>; }; }) => Object.values(state.images.byId);

export const getSortOrder = (state: { images: { sortOrder: string; }; }) => state.images.sortOrder;
