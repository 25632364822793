import firebase from 'firebase/app';
import {Dispatch} from "redux";
import {byId as byIdReducer} from "./index";
import _ from 'lodash';
import {ImageObject} from "./types";
import {addPhotoMetadataToFirebase, updatePhotoMetadata} from "../../admin/requests";

export const initGallery = () => async (dispatch: Dispatch, getState: Function) => {
    const {images: {byId}} = getState();
    if (!Object.values(byId).length) {
        const imagesSnapshot = await firebase.database().ref('photos').once('value');
        const rawImageMetadata = imagesSnapshot.val();

        const metadataWithSrc = await Promise.all(
            _.compact(Object.values(rawImageMetadata)).map((img: { name: any }) =>
                firebase.storage().ref(`/photos/${img.name}`)
                    .getDownloadURL()
                    .then(src => ({...img, src}))
                    .catch(e => console.log(e))
        ))
            .then(data => _.compact(_.flatten(data)));
        dispatch(byIdReducer.actions.add(metadataWithSrc));
    }
};

export const addPhotoMetadata = (photoMetadata: ImageObject, localImageSrc: string) => async (dispatch: Dispatch, getState: Function) => {
    addPhotoMetadataToFirebase(photoMetadata);
    dispatch(byIdReducer.actions.add([{...photoMetadata, src: localImageSrc}]));
};

export const editPhotoMetadata = (photoMetadata: ImageObject) => async (dispatch: Dispatch, getState: Function) => {
    updatePhotoMetadata(photoMetadata);
    dispatch(byIdReducer.actions.edit(photoMetadata))
}
