import { useEffect, useState } from 'react';
import bcrypt from 'bcryptjs';
import adminPasswordHash from '../auth/adminPasswordHash';

const useAuth = () => {
  const [isAdminUser, setIsAdminUser] = useState(false);
  useEffect(() => {
    const myCat = localStorage.getItem('myCat');
    if (myCat) {
      setIsAdminUser(bcrypt.compareSync(myCat, adminPasswordHash.value));
    }
  }, []);
  return isAdminUser;
};

export default useAuth;
