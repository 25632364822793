// Editor | Code Style | JavaScript | Imports
import React, { useState } from 'react';
import { Card, Form, Icon, Modal } from 'antd';
import firebase from 'firebase/app';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import uuid from 'uuid/v4';
import { getBase64 } from 'utils/file-upload';
import { addBase64PhotoToFirebase, addPhotoToFirebase } from 'admin/requests';
import getSizeRatio from 'admin/AddPhoto/utilities/getSizeRatio';
import {
  addPhotoMetadata as addPhotoMetadataAction,
  editPhotoMetadata as editPhotoMetadataAction,
} from 'image-gallery/reducer/thunks.tsx';
import moment from 'moment';
import { connect } from 'react-redux';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import UploadButton from './components/UploadButton';

const { confirm } = Modal;

const AddPhoto = ({ addPhotoMetadata, editPhotoMetadata }) => {
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const rotateImage = (fileObj, fileName) => {
    const { metadata } = fileObj;
    getBase64(fileObj, imageUrl => {
      const image = new Image();
      image.addEventListener(
        'load',
        () => {
          const canvas = document.createElement('canvas');
          const { width, height } = image;
          // noinspection JSSuspiciousNameCombination
          canvas.width = height;
          // noinspection JSSuspiciousNameCombination
          canvas.height = width;
          const context = canvas.getContext('2d');
          context.translate(canvas.width / 2, canvas.height / 2);
          context.rotate((90 * Math.PI) / 180);
          context.drawImage(image, -width / 2, -height / 2);
          context.rotate(-(90 * Math.PI) / 180);
          context.translate(-canvas.width / 2, -canvas.height / 2);
          addBase64PhotoToFirebase(canvas.toDataURL(), metadata.name);
          const thumbnails = [...document.querySelectorAll('.ant-upload-list-item-thumbnail')];
          const thumbnail = thumbnails.find(tn => tn.outerHTML.includes(fileName));
          thumbnail.style.transform = 'rotate(90deg)';
          // noinspection JSSuspiciousNameCombination
          const rotatedPhotoMetadata = {
            ...metadata,
            height: metadata.width,
            width: metadata.height,
          };
          editPhotoMetadata(rotatedPhotoMetadata);
        },
        false,
      );
      image.src = imageUrl;
    });
    return Promise.resolve();
  };

  const uploadRequestHandler = ({ onSuccess, onProgress, onError, file }) => {
    const checkInfo = () => {
      setTimeout(() => {
        if (!uploadedFiles) {
          checkInfo();
        } else {
          const photoId = uuid();
          Object.defineProperty(file, 'name', {
            writable: true,
            value: photoId,
          });
          addPhotoToFirebase(file).on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snapshot => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  break;
                case firebase.storage.TaskState.RUNNING:
                  onProgress({ percent: progress });
                  break;
                default:
                // do nothing
              }
            },
            error => onError(error),
            () => onSuccess(null, file),
          );
        }
      }, 100);
    };

    checkInfo();
  };

  const handleUpload = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        const image = new Image();
        image.addEventListener(
          'load',
          () => {
            const { height, width } = getSizeRatio(image.height, image.width, 0.2);
            const photoMetadata = {
              height,
              width,
              id: info.file.originFileObj.name,
              capturedDate: moment(info.file.lastModified).format('YYYY-MM-DD'),
              name: info.file.originFileObj.name,
            };
            image.photoId = info.file.originFileObj.name;
            info.file.originFileObj.metadata = photoMetadata;
            addPhotoMetadata(photoMetadata, imageUrl);
            setLoading(false);
            setUploadedFiles([...uploadedFiles, image]); // :<
            setUploadedFileCount(uploadedFileCount + 1);
          },
          false,
        );
        image.src = imageUrl;
      });
    }
  };

  const linkToGallery = (
    <div>
      <Link to="/">
        <Icon type="back" />
        To Gallery
      </Link>
    </div>
  );

  const handlePreviewClick = file => {
    confirm({
      title: 'Do you want to rotate this image?',
      icon: <ExclamationCircleOutlined />,
      // content: <img src={}>,
      onOk: () => rotateImage(file.originFileObj, file.name),
      onCancel: () => {},
    });
  };

  return (
    <div style={{ backgroundColor: '#FEFAF0', height: '100vh' }}>
      <Card
        title="Add Photo"
        className="admin-form"
        style={{ margin: 'auto', marginTop: '20px' }}
        extra={linkToGallery}
      >
        <Form>
          <Form.Item>
            <UploadButton
              onUpload={handleUpload}
              uploadedFiles={uploadedFiles}
              loading={loading}
              uploadRequestHandler={uploadRequestHandler}
              onPreviewClick={handlePreviewClick}
            />
            {!!uploadedFiles.length && <p>Tap on preview to rotate image.</p>}
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default connect(null, {
  addPhotoMetadata: addPhotoMetadataAction,
  editPhotoMetadata: editPhotoMetadataAction,
})(AddPhoto);
