import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCco63mvMyQ2epSj8_fyg5jTBMxuenY9Sw',
  authDomain: 'leeroy-photo-app.firebaseapp.com',
  databaseURL: 'https://leeroy-photo-app.firebaseio.com',
  projectId: 'leeroy-photo-app',
  storageBucket: 'leeroy-photo-app.appspot.com',
  messagingSenderId: '165837744346',
  appId: '1:165837744346:web:fea365073f9b47ff06dde3',
  measurementId: 'G-XJN6SFQGG4',
};

firebase.initializeApp(firebaseConfig);

firebase.analytics();

const storage = firebase.storage();

const functions = firebase.functions();

export default { storage, functions };
