import React from 'react';
import GalleryComponent from 'react-photo-gallery';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getImagesForMonthAndYear, getSortOrder } from '../reducer/selectors.tsx';

const classForMonthPhotoCount = photoCount => {
  switch (photoCount) {
    case 1:
      return 'single-photo';
    case 2:
      return 'couple-photos';
    default:
      return undefined;
  }
};

const MonthGroup = ({ month, year, openLightbox }) => {
  const images = useSelector(getImagesForMonthAndYear(month, year));
  const sortOrder = useSelector(getSortOrder);
  const orderedImages = _.orderBy(images, ['day'], sortOrder);

  return (
    <div style={{ backgroundColor: '#FEFAF0' }}>
      <div className="month-header">
        <h2>{month}</h2>
      </div>
      <div className={classForMonthPhotoCount(images.length)}>
        <GalleryComponent
          photos={orderedImages.map((img, index) => ({ ...img, index }))}
          onClick={openLightbox}
        />
      </div>
    </div>
  );
};

export default MonthGroup;
