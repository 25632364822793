import firebase from 'firebase/app';

export const addPhotoToFirebase = file =>
  firebase
    .storage()
    .ref(`photos/${file.name}`)
    .put(file);

export const addBase64PhotoToFirebase = (dataUrl, fileName) =>
  firebase
    .storage()
    .ref(`photos/${fileName}`)
    .putString(dataUrl, 'data_url');

export const addPhotoMetadataToFirebase = photoMetadata =>
  firebase
    .database()
    .ref(`/photos/${photoMetadata.id}`)
    .set(photoMetadata);

export const updatePhotoMetadata = photoMetadata =>
  firebase
    .database()
    .ref(`/photos/${photoMetadata.id}`)
    .set(photoMetadata);

export default {
  addPhotoToFirebase,
};
